import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Subscribe from '~components/Subscribe'
import { motion } from 'framer-motion'
	
const SubscribePopup = ({ className, onClose, onSubscribe }) => {

	return (
		<>
			<Global styles={css`
				html, body {
					overflow: hidden;
				}`
			}/>
			<Wrap 
				className={className}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
			>
				<Inner>
					<StyledSubscribe open onClose={() => onClose()} focusOnOpen noTitle/>
				</Inner>
				<BackDrop onClick={()=> onClose()}/>
			</Wrap>
		</>
	)
}
	
const Wrap = styled(motion.div)`
	padding: 4vw;
	position: fixed;
	z-index: 101;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;

`
const Inner = styled.div`
	background-color: #fff;
	padding: 1vw 4vw 4vw;	
	position: relative;
	z-index: 103;
	width: 100%;
`
const StyledSubscribe = styled(Subscribe)`
	width: 100%;
`
const BackDrop = styled.button`
	position: fixed;
	z-index: 102;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ffffff67;
`
	
SubscribePopup.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	onSubscribe: PropTypes.func
}
	
export default SubscribePopup