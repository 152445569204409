import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import ImagesScroll from '~components/ImagesScroll'
import { useSiteState } from '~context/siteContext'
import Subscribe from '~components/Subscribe'
import Section from '~components/Section'
import { mobile } from '~styles/global'
import useSiteSettings from '~utils/useSiteSettings'
import SubscribePopUp from '~components/SubscribePopUp'
import { AnimatePresence } from 'framer-motion'

const IndexPage = ({ data }) => {
	const { page } = data
	const [ siteState, setSiteState ] = useSiteState()
	const [initial, setInitial] = useState(undefined)
	const { newsletterSignUpTitle } = useSiteSettings()
	const [popUp, setPopUp] = useState(false)

	useEffect(() => {
		setSiteState(prevState => ({
			...prevState,
			fixedLayout: false
		}))
	}, [])

	useEffect(() => {
		const windowHeight = window.innerHeight
		setInitial(windowHeight)
	}, [])

	return (
		<>	
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<AnimatePresence>{popUp && <SubscribePopUp onClose={() => setPopUp(false)}/>}</AnimatePresence>
			<SubscribeOverlay initial={initial}>
				<SubscribeInner>
					<WhiteSubscribe white close/>
					<TriggerSubscribe className='h4' onClick={() => setPopUp(true)}>
						<TriggerTitle >{newsletterSignUpTitle}</TriggerTitle>
						{/* <TriggerButton >
							<Email>
								Enter Your Email
							</Email>
							<SubscribeButton type="submit" className='h4'>Subscribe</SubscribeButton>
						</TriggerButton> */}
					</TriggerSubscribe>
				</SubscribeInner>
			</SubscribeOverlay>
			<ImagesScroll mobileProjects={page.homeProjectsMobile} desktopProjects={page.homeProjectsDesktop}/>
			<DataViewer data={page} name="page"/>
		</>
	)
}

const SubscribeOverlay = styled.div`
	position: fixed;
	pointer-events: none;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 100;
	box-sizing: border-box;
	bottom: 2vw;
	${mobile}{
		bottom: 0;
		background-color: white;
		padding-bottom: 4vw;
		pointer-events: all;
	}
`
const SubscribeInner = styled(Section)`
`
const WhiteSubscribe = styled(Subscribe)`
	color: var(--white);
	pointer-events: all;
	grid-column: 7/13;
	${mobile}{
		display: none;
	}
`
const TriggerSubscribe = styled.button`
	display: none;
	position: relative;	
	text-align: left;
	/* border-bottom: 1px solid var(--white); */
	/* color: var(--white); */
	grid-column: span 12;
	${mobile}{
		display: block;
		padding-top: 4vw;
	}
`
const TriggerButton = styled.button`
	width: 100%;
	display: flex;
	justify-content: space-between;
	text-transform: uppercase;
	padding-bottom: 0.18rem;
`
const TriggerTitle = styled.div`
	margin-bottom: 1vw;
`
const Email = styled.div`
	
`
const SubscribeButton = styled.div`

`

export const query = graphql`
  query HomeQuery {
    page: sanityHomePage {
      title
			homeProjectsDesktop {
				_key
				image{
					...imageWithAlt
				}
				project{
					slug{
						current
					}
				}
			}
			homeProjectsMobile {
				_key
				image{
					...imageWithAlt
				}
				project{
					slug{
						current
					}
				}
			}
			seo{
				...seo
			}
    }
  }
`

IndexPage.propTypes = {
	data: PropTypes.object,
}

export default IndexPage
