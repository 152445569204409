import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useInView } from 'react-intersection-observer'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'

const InvisibleContainer = ({ className, i, onChange, slug, fixedHeight }) => {

	const [ref, inView, entry] = useInView({threshold: 0.8})

	useEffect(() => {
		if(inView && onChange) {
			onChange(i)
		}
	}, [inView, onChange])

	return (
		<div className={className} ref={ref}>
			<Inner fixedHeight={fixedHeight}>
				<StyledLink to={resolveLink({_type: 'project', slug: slug})}/>
			</Inner>
		</div>
	)
}

const Inner = styled.div`
	height: 100vh;
	${mobile}{
		height: ${props => props.fixedHeight}px;
	}
`

const StyledLink = styled(Link)`
	width: 100%;
	height: 100%;
	display: block;
`

InvisibleContainer.propTypes = {
	className: PropTypes.string,
	i: PropTypes.number,
	onChange: PropTypes.func,
	slug: PropTypes.object,
	fixedHeight: PropTypes.number,
}

export default InvisibleContainer