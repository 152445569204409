import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import useBreakpoint from '~utils/useBreakpoint'
import FixedImage from '~components/FixedImage'
import InvisibleContainer from '~components/InvisibleContainer'

const ImagesScroll = ({ className, desktopProjects, mobileProjects }) => {

	const { isMobile, isTablet, isDesktop } = useBreakpoint()
	const imagesRef = useRef()
	const [currentIndex, setCurrentIndex] = useState(0)
	const [fixedHeight, setFixedHeight] = useState(undefined)
	const [windowWidth, setWindowWidth] = useState(undefined)

	const loadImage = (imageIndex) => {
		if (imageIndex > currentIndex + 3) {
			return false
		} else if (imageIndex < currentIndex - 3) {
			return false
		} else {
			return true
		}
	}

	// const handleResize = () => {
	// 	setFixedHeight(window.innerHeight)
	// }

	useEffect(() => {
		setFixedHeight(window.innerHeight)
		// window.addEventListener('resize', handleResize)
		// return () => window.removeEventListener('resize', handleResize)
	}, [])


	return (
		<Wrap className={className} ref={imagesRef} css={css`position: relative;`}>
			{(isMobile || isTablet) &&
				<InvisibleImages>
					{mobileProjects.map((project, i) => 
						<InvisibleContainer 
							key={project._key} 
							i={i} 
							onChange={value => setCurrentIndex(value)} slug={project?.project?.slug}
							fixedHeight={fixedHeight}
						/>
					)}
				</InvisibleImages>
			}
			{isDesktop &&
				<InvisibleImages>
					{desktopProjects.map((project, i) => 
						<InvisibleContainer 
							key={project._key} 
							i={i} 
							onChange={value => setCurrentIndex(value)} slug={project?.project?.slug}
							fixedHeight={fixedHeight}
						/>
					)}
				</InvisibleImages>
			}		
			{(isMobile || isTablet) &&
				<Images>
					{mobileProjects.map((project, i) => 
						<FixedImage 
							key={project._key} 
							image={project.image} 
							showImage={loadImage(i)}
							fixedHeight={fixedHeight}
						/>
					)}
				</Images>
			}
			{isDesktop &&
				<Images>
					{desktopProjects.map((project, i) => 
						<FixedImage 
							key={project._key} 
							image={project.image} 
							showImage={loadImage(i)}
							fixedHeight={fixedHeight}
						/>
					)}
				</Images>
			}
		</Wrap>
	)
}


const Images = styled.div`
	background: black;
`
const InvisibleImages = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
`

const Wrap = styled.div`
	background: black;
`

ImagesScroll.propTypes = {
	className: PropTypes.string,
	desktopProjects: PropTypes.array,
	mobileProjects: PropTypes.array,
}

export default ImagesScroll