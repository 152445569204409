import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'

const FixedImage = ({ className, image, showImage, fixedHeight }) => {

	return (
		<Wrap className={className} fixedHeight={fixedHeight}>
			{showImage && <StyledImage image={image} cover fixedHeight={fixedHeight}/>}
		</Wrap>
	)
}

const Wrap = styled.div`
	overflow: hidden;
	clip-path: inset(0);
	position: relative;
	transition: height 0.5s;
	transition: min-height 0.3s;
	height: 100vh;
	${mobile}{
		min-height: ${props => props.fixedHeight}px;
	}
`

const StyledImage = styled(Image)`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	object-fit: cover;
	height: 100vh;
	${mobile}{
		height: ${props => props.fixedHeight}px;
	}
`

FixedImage.propTypes = {
	className: PropTypes.string,
	showImage: PropTypes.bool,
	image: PropTypes.object,
	fixedHeight: PropTypes.number,
}

export default FixedImage